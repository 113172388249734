@import "~bootstrap/scss/functions.scss";
@import "~bootstrap/scss/variables.scss";
@import "~bootstrap/scss/mixins.scss";

$enable-negative-margins: true;

$body-bg-2: darken(#ffffff, 5%);

$brand: #e4437e;
$custom-colors: (
  "primary": #e4437e,
  "simple": $body-bg-2,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

$card-border-color: #ced4da;
$text-muted: #6c757d;
$text-muteder: #8e969d;

:root {
  --body-bg-2: #{$body-bg-2};
  --card-border-color: #{$card-border-color};
  --text-muted: #{$text-muted};
  --text-muteder: #{$text-muteder};
}

$tooltip-color: $body-color;
$tooltip-bg: $body-bg-2;
$tooltip-arrow-color: $body-bg-2;
$tooltip-opacity: 0.9;

@import "./dark.scss";

.theme-bg {
  color: white;
  background: linear-gradient(
    -45deg,
    #e4437e,
    #e4437e,
    #cd336b,
    #cd336b,
    #ae0a43,
    #ae0a43
  );
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

html,
body,
main,
#root,
#root > div {
  height: 100%;
  width: 100%;
}

.btn.btn-primary,
.btn.btn-primary:hover {
  color: white;
}

#root > main {
  display: flex;
  flex-direction: column;
}

#root > main > div {
  flex: 1 0 auto;
}

:root {
  --toastify-font-family: $font-family-sans-serif;
}

.divider-text {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre;
}

.fs-smaller {
  font-size: 0.75em;
}

.divider-text::before,
.divider-text::after {
  content: "";
  margin-left: 0.5em;
  margin-right: 0.5em;
  height: 1px;
  width: 50%;
  background-color: white;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--text-muted);
}

.divider-v {
  height: 100%;
  width: 1px;
  background-color: var(--text-muted);
}

.discrete-link {
  color: inherit !important;
  text-decoration: none !important;
}

.discrete-link:hover {
  color: inherit !important;
  text-decoration: underline !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.btn.btn-highlight:hover {
  border-color: lighten($body-color, 40%);
}

.btn.btn-search:hover,
.btn.btn-search:active {
  border-color: transparent;
  background-color: darken($body-bg, 10%);
}

.btn.btn-gold {
  transition: filter 0.2s ease;
  filter: saturate(1);

  background: radial-gradient(
      ellipse farthest-corner at right bottom,
      #fedb37 0%,
      #fdb931 8%,
      #9f7928 30%,
      #8a6e2f 40%,
      transparent 80%
    ),
    radial-gradient(
      ellipse farthest-corner at left top,
      #ffffff 0%,
      #ffffac 8%,
      #d1b464 25%,
      #5d4a1f 62.5%,
      #5d4a1f 100%
    );
  background-origin: border-box;

  --bs-btn-hover-border-color: white;
  --bs-btn-active-border-color: white;
  --bs-btn-hover-color: white;
  --bs-btn-active-color: white;

  &:hover {
    filter: saturate(1.3);
  }
}

.btn.btn-icon {
  font-size: x-large;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
