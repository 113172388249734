@function toRGB($color) {
  @return "rgb(" + red($color) + ", " + green($color) + ", " + blue($color) +
    ")";
}

.dark-mode {
  $body-color: $light;
  $body-bg: $dark;

  $body-bg-2: lighten($dark, 5%);
  --body-bg-2: #{$body-bg-2};

  $card-border-color: darken(white, 70%);
  --card-border-color: #{$card-border-color};

  $text-muted: rgba($light, 0.5);
  --text-muted: #{$text-muted};

  $text-muteder: rgba($light, 0.3);
  --text-muteder: #{$text-muteder};

  $theme-colors: (
    "white": $dark,
    "primary": #e4437e,
    "simple": $body-bg-2,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  );

  /* redefine theme color variables */
  @each $color, $value in $theme-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }

  /* redefine theme color rgb vars (used for bg- colors) */
  $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");

  @each $color, $value in $theme-colors-rgb {
    --#{$variable-prefix}#{$color}-rgb: #{$value};
  }

  $navbar-light-color: rgba($light, 0.5);
  $navbar-light-brand-color: $light;
  $navbar-light-brand-hover-color: $white;
  $navbar-light-hover-color: $white;
  $navbar-light-active-color: $light;
  $navbar-light-toggler-border-color: $navbar-light-color;
  $navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$light}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");

  $card-border-color: darken(white, 70%);
  $card-color: darken(white, 70%);
  $card-color-hover: darken(white, 70%);

  $tooltip-color: $body-color;
  $tooltip-bg: $body-bg-2;
  $tooltip-arrow-color: $body-bg-2;
  $tooltip-opacity: 0.9;

  #logo,
  .navbar-icon {
    fill: $body-color;
  }

  .empty-icons {
    color: rgba($light, 0.25) !important;
  }

  .filled-icons {
    color: $light !important;
  }

  a:link {
    color: $light;
  }

  a:visited {
    color: $light;
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"],
  input[type="search"],
  textarea,
  textarea.form-control,
  select,
  input[type="text"]:focus,
  input[type="password"]:focus,
  input[type="email"]:focus,
  input[type="number"]:focus,
  input[type="search"]:focus,
  textarea:focus,
  textarea.form-control:focus,
  select:focus {
    background-color: $body-bg-2;
    border-color: $card-border-color;
    color: $light;
  }

  .form-control:disabled {
    background-color: #383d43 !important;
    color: rgba($light, 0.5);
  }

  input:-internal-autofill-selected,
  input:-internal-autofill-selected:focus {
    -webkit-text-fill-color: $light !important;
    box-shadow: 0 0 0 50px lighten($body-bg-2, 5%) inset;
  }

  // React Loading Skeleton
  .react-loading-skeleton {
    --base-color: #{lighten($dark, 5%)};
    --highlight-color: #{lighten($dark, 10%)};
  }

  // React Select
  .react-select-container {
    .react-select__control {
      background-color: $body-bg-2 !important;
      border-color: $card-border-color !important;
      color: $light !important;
    }

    .react-select__input,
    .react-select__input:focus {
      background-color: transparent !important;
    }

    .react-select__single-value {
      color: $light !important;
    }

    .react-select__menu {
      background-color: $body-bg-2 !important;
      color: $light !important;
    }

    .react-select__option--is-focused {
      background-color: lighten($dark, 10%) !important;
    }

    .react-select__option:active,
    .react-select__option--is-focused:active {
      background-color: lighten($dark, 20%) !important;
    }

    .react-select__option--is-selected {
      background-color: #21589f !important;
    }
  }

  .modal-content {
    background-color: $dark !important;
  }

  .nav-link,
  .nav-link:focus,
  .nav-link:hover {
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
    border-bottom-color: transparent;
    color: $light !important;
  }

  table {
    color: $light !important;

    * {
      color: $light !important;
    }
  }

  table.table-striped {
    --bs-table-striped-bg: rgb(0 0 0 / 12%) !important;
  }

  table > tbody > tr {
    border-color: darken(white, 70%) !important;
  }

  .table-hover > tbody > tr:hover > * {
    background-color: darken(white, 80%);
    color: $light !important;
  }

  .list-group-item,
  .modal-content,
  .popover,
  .popover-header,
  .Toastify__toast {
    background-color: $body-bg !important;
  }

  .list-group-item,
  .modal-content,
  .modal-header,
  .modal-body,
  .modal-footer,
  .popover,
  .popover-header,
  .Toastify__toast {
    color: $light !important;
    border-color: $card-border-color !important;
  }

  .bs-popover-end .popover-arrow {
    &::before {
      border-right-color: $card-border-color !important;
    }

    &::after {
      border-right-color: $body-bg !important;
    }
  }

  .bs-popover-top .popover-arrow {
    &::before {
      border-top-color: $card-border-color !important;
    }

    &::after {
      border-top-color: $body-bg !important;
    }
  }

  .bs-popover-bottom .popover-arrow {
    &::before {
      border-bottom-color: $card-border-color !important;
    }

    &::after {
      border-bottom-color: $body-bg !important;
    }
  }

  .bs-popover-start .popover-arrow {
    &::before {
      border-left-color: $card-border-color !important;
    }

    &::after {
      border-left-color: $body-bg !important;
    }
  }

  .btn.btn-light {
    color: black;
  }

  .btn-close {
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e")
      center/1em auto no-repeat !important;
  }

  .btn-outline-danger {
    color: #dc3545 !important;
    border-color: #dc3545 !important;
  }

  .btn-outline-danger:hover {
    color: #fff !important;
  }

  .btn.btn-highlight:hover {
    border-color: $body-color;
  }

  .btn.btn-search:hover,
  .btn.btn-search:active {
    border-color: transparent;
    background-color: lighten($body-bg, 10%);
  }

  .dropdown-menu {
    background-color: $dark !important;
    border-color: darken(white, 70%) !important;
  }

  .dropdown-item:hover,
  .dropdown-item:focus,
  .dropdown-item.active {
    background-color: darken(white, 80%) !important;
    color: $light !important;
  }

  .dropdown-divider {
    background-color: darken(white, 70%) !important;
  }

  .popover-body {
    color: $body-color !important;
  }

  --#{$variable-prefix}body-color: #{$body-color};
  --#{$variable-prefix}body-color-rgb: #{toRGB($body-color)};
  --#{$variable-prefix}body-bg: #{$body-bg};
  --#{$variable-prefix}body-bg-rgb: #{toRGB($body-bg)};
  @import "~bootstrap/scss/bootstrap.scss";
}
